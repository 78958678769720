/* eslint-disable comma-dangle */
/* eslint-disable quotes */
// The list is in inverted chronological order.
// First items are the latest.

module.exports = [
 
  {
    id: "1",
    image:
      "https://storage.wsus.me/uploads/sertifikat/fundamental-fe.JPG",
    href: "https://www.dicoding.com/certificates/1RXY6VYEMZVM",
    title: "Dicoding - Belajar Fundamental Front-End Web Development",
    date: "Agustus 8th, 2023",
    desc: `Valid until: Agustus 8th, 2026`,
  },
  
  {
    id: "7",
    image: "https://storage.wsus.me/uploads/sertifikat/pemula-web.JPG",
    href: "https://www.dicoding.com/certificates/EYX4Y7LRWZDL",
    title: "Dicoding - Belajar Membuat Front-End Web untuk Pemula",
    date: "Sep 11st, 2023",
    desc: `Valid until: Sep 11st, 2026.`,
  },
  {
    id: "3",
    image: "https://storage.wsus.me/uploads/sertifikat/dasar-web.JPG",
    href: "https://www.dicoding.com/certificates/1RXY0Y94QZVM",
    title: "Dicoding - Belajar Dasar Pemrograman Web",
    date: "Sep 12st, 2023",
    desc: `Valid until: Sep 12st, 2026.`,
  },
  
  {
    id: "2",
    image: "https://storage.wsus.me/uploads/sertifikat/dasar-be.PNG",
    href: "https://www.dicoding.com/certificates/81P23D2LNXOY",
    title: "Dicoding - Belajar Membuat Aplikasi Back-End untuk Pemula",
    date: "Agustus 8st, 2023",
    desc: `Valid until: Agustus 8st, 2026.`,
  },
];
